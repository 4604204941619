<template>
  <v-container
    fluid
    tag="section"
  >
    <!--  -->
    <v-row justify="center">
      <!--  -->
      <v-col
        cols="12"
        md="8"
      >
        <!--  -->
        <base-material-card>
          <!--  -->
          <template v-slot:heading>
            <div class="display-2 font-weight-light">
              Edit Your Forum
            </div>
          </template>
          <!--  -->
          <v-form>
            <!--  -->
            <v-container class="py-0">
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    :value="Forum.data.id"
                    label="ID"
                    disabled
                    append-icon="mdi-check-outline"
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    v-model="Forum.data.title"
                    label="Title"
                    clearable
                  >
                    <!--  -->
                  </v-text-field>
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-textarea
                    v-model="Forum.data.post"
                    label="Post Artikel"
                    clearable
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-select
                    v-model="Forum.data.status"
                    :items="status_items"
                    :rules="categoryRules"
                    class="purple-input"
                    label="Pilih Status"
                    item-text="name"
                    item-value="id"
                  >
                    <!-- Template for render data when the select is expanded -->
                    <template
                      slot="item"
                      slot-scope="data"
                    >
                      <!-- Divider and Header-->
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item" />
                      </template>
                      <!-- Normal item -->
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.name" />
                          <v-list-item-subtitle
                            v-html="
                              data.item.parent ? data.item.parent.name : ''
                            "
                          />
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <!--  -->

              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    :value="Forum.data.created_at | moment('D MMM YYYY')"
                    label="Di Buat"
                    disabled
                    append-icon="mdi-check-outline"
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    :value="Forum.data.updated_at | moment('D MMM YYYY')"
                    label="Di Perbaharui"
                    disabled
                    append-icon="mdi-check-outline"
                  />
                </v-col>
              </v-row>
              <!--  -->
              <v-row>
                <!--  -->
                <v-col
                  cols="12"
                  md="12"
                >
                  <!--  -->
                  <v-text-field
                    :value="Forum.data.user_id"
                    label="User ID"
                    disabled
                    append-icon="mdi-check-outline"
                  />
                </v-col>
              </v-row>
              <!--  -->

              <!--  -->
              <v-row>
                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    color="success"
                    class="mr-0"
                    @click="getUpdate"
                  >
                    SUNTING
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    data () {
      return {
        Forum: {
          meta: {},
          data: {
            title: '',
            status: '',
            post: '',
          },
        },
        status_items: ['open', 'close'],
        categoryRules: [value => value !== '' || 'Kategori wajib diisi'],
        isEditing: false,
      }
    },
    mounted () {
      this.getForum()
    },
    methods: {
      getUpdate () {
        axios
          .patch('/v1/forum/thread/' + this.$route.params.id, {
            title: this.Forum.data.title,
            post: this.Forum.data.post,
            status: this.Forum.data.status,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({ path: '/forum' })
              this.$toast.success('Berhasil Di Sunting', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            }
          })
      },
      setForum (data) {
        this.Forum = data
        console.log(this.Forum)
      },
      getForum () {
        axios
          .get('/v1/forum/thread/' + this.$route.params.id)
          .then(response => {
            this.setForum(response.data)
          })
          .catch(e => {
            console.error(e)
          })
      },
    },
  }
</script>

<style></style>
